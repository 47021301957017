t<template>
    <div>
      <img class="d-block" width="300" :src="require('@/assets/logo.png')" alt="Group Trip">
      <h4 class="subheader subheaderGrey--text mt-2 ml-5 text-bold text-uppercase">Verify your Email</h4>

      <v-card outlined class="mt-5 pt-5 pb-2">
        <h2 v-if="loaded & verified" class="success--text text-center">
            {{text}}
        </h2>
        <h3 v-if="loaded & !verified" class="success--text text-center">Please check your email and click on the verification button !</h3>
        <v-divider v-if="sendAgain" class="mt-2"></v-divider>
        <v-card-actions v-if="sendAgain">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="verify">Send Email Again</v-btn>
        </v-card-actions>
      </v-card>
    </div>
</template>

<script>
import auth from '@/web_services/auth'
import axios from 'axios'

export default {
  data () {
    return {
      loaded: false,
      verified: false,
      text: '',
      sendAgain: false
    }
  },
  methods: {
    verify () {
      const query = this.$route.query
      const body = {
        id: query.id,
        hash: query.hash,
        signature: query.signature,
        expires: query.expires
      }
      auth.verify(body).then(res => {
        // if (res) {
        //   if (res.data.SuccessNum === 200) {
        this.verified = true
        this.text = res.data.message
        this.$cookies.set('gt_user_token', res.data.data.token, new Date(res.data.data.expire_at))
        this.$cookies.set('gt_user_role', res.data.data.user.role, new Date(res.data.data.expire_at))
        this.$store.dispatch('setUserData', res.data.data.user)
        axios.defaults.headers.common.Authorization = 'Bearer ' + this.$cookies.get('gt_user_token')
        if (res.data.data.user.role.name === 'Traveler') {
          setTimeout(() => {
            if (this.$cookies.isKey('gt_user_token')) this.$router.push({ path: '/' })
            else this.$router.push({ path: '/auth/login' })
          }, 3000)
        }
        //   }
        // }
      }).catch(err => {
        if (this.err) this.text = err.response.data.data.message
        else this.text = 'Something went wrong'
        this.sendAgain = true
      }).finally(() => { this.loaded = true })
    }
  },
  created () {
    if (this.$route.query.id) {
      this.verify()
    } else {
      this.loaded = true
    }
  }
}
</script>

<style>
.v-text-field--outlined fieldset {
    border-color: #cecece !important;
}
.field-item {
    padding-right: 15px;
    border-right: 1px solid #cecece;
}
</style>
